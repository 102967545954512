import { useEffect } from "react";

declare global {
  interface Window {
    fbq?: MetaPixelFunction;
  }
}

type FbqMethod = "track" | "init" | "trackCustom";
type FbqArgs = [FbqMethod, ...any[]];

interface MetaPixelFunction {
  (...args: FbqArgs): void;
  queue?: FbqArgs[];
  loaded?: boolean;
  version?: string;
}

const useMetaPixel = (pixelId: string) => {
  useEffect(() => {
    if (typeof window === "undefined" || !pixelId) return;

    // Check if Pixel is already loaded
    if (!window.fbq) {
      const fbq: MetaPixelFunction = (...args) => {
        (fbq.queue = fbq.queue || []).push(args);
      };

      fbq.queue = [];
      fbq.loaded = true;
      fbq.version = "2.0";
      window.fbq = fbq;

      // Insert script inside <head>
      const script = document.createElement("script");
      script.async = true;
      script.src = "https://connect.facebook.net/en_US/fbevents.js";

      const head = document.head || document.getElementsByTagName("head")[0];
      head.appendChild(script);

      // Insert <noscript> image pixel next to the script
      const noscript = document.createElement("noscript");
      const img = document.createElement("img");
      img.height = 1;
      img.width = 1;
      img.style.display = "none";
      img.src = `https://www.facebook.com/tr?id=${pixelId}&ev=PageView&noscript=1`;

      noscript.appendChild(img);
      head.appendChild(noscript);
    }

    // Initialize Meta Pixel and track page view
    window.fbq("init", pixelId);
    window.fbq("track", "PageView");
  }, [pixelId]);
};

export const sendMetaPurchaseEvent = (
  invoiceId: string,
  bookId: string,
  name: string,
  publishingHouse: string,
  author: string,
  price: number
) => {
  if (window.fbq) {
    window.fbq('track', 'Purchase', {
      transaction_id: invoiceId,
      currency: 'UAH',
      value: price,
      content_type: 'book',
      contents: [{
        id: bookId,
        title: name,
        brand: author,
        affiliation: publishingHouse,
        price: price
      }]
    });
  }
};

export const sendMetaObtainFreeBookEvent = (bookId: string) => {
  if (window.fbq) {
    window.fbq('trackCustom', 'ObtainFreeBook', {
      category: 'Purchases',
      label: bookId
    });
  }
};

export const sendMetaSelectBookEvent = (bookId: string) => {
  if (window.fbq) {
    window.fbq('track', 'ViewContent', {
      content_type: 'Book',
      content_id: bookId
    });
  }
};

export const sendMetaSelectPublisherEvent = (publisher: string) => {
  if (window.fbq) {
    window.fbq('trackCustom', 'SelectPublisher', {
      content_type: 'Publisher',
      content_id: publisher
    });
  }
};

export const sendMetaSearchEvent = (searchParam: string) => {
  if (window.fbq) {
    window.fbq('track', 'Search', {
      search_string: searchParam
    });
  }
};


export default useMetaPixel;
