import {
  Button,
  Center,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Show,
  Stack,
  Text,
} from "@chakra-ui/react";
import { FunctionComponent, useState } from "react";
import { IoMdClose } from "react-icons/io";

import { SearchIcon } from "../../../ui/icons/SearchIcon";
import vector from "../../../assets/Hero-mobile.svg";
import { useNavigate } from "react-router-dom";
import { analyticsEvents } from "../../../utilities/analyticsEvents";
import { sendMetaSearchEvent } from "../../../hooks/useFacebookPixel";
interface SearchPanelProps {
  isOpen: boolean;
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
}

const SearchPanel: FunctionComponent<SearchPanelProps> = ({
  isOpen,
  onClose,
}) => {
  const navigate = useNavigate();
  const [search, setSearch] = useState<string>("");

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      analyticsEvents.search(search);
      sendMetaSearchEvent(search);

      searchBooks();
    }
  };

  const searchBooks = () => {
    onClose(false);
    analyticsEvents.search(search);
    sendMetaSearchEvent(search);
    search !== "" && navigate(`/catalogue?searchParameter=${search}`);
  };

  return (
    <Drawer
      isOpen={isOpen}
      onClose={() => onClose(false)}
      placement="top"
      blockScrollOnMount={false}
    >
      <DrawerOverlay />
      <DrawerBody>
        <DrawerContent width="fit-content !important">
          <Flex
            position="relative"
            width="100vw"
            py={{
              base: "5rem",
              md: "9rem",
              xl: "12rem",
              "2xl": "17rem",
            }}
            px={{
              base: "1.25rem",
              sm: "10rem",
              md: "15rem",
              xl: "17.5rem",
              "2xl": "26rem",
            }}
            backgroundColor="grey"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Stack
              width="100%"
              alignItems={{ base: "center", sm: "flex-start" }}
              gap={{ base: "1rem", xl: "2rem" }}
            >
              <Text
                textAlign="center"
                zIndex={1000}
                fontWeight={600}
                fontSize={{
                  base: "1.75rem",
                  sm: "1.5rem",
                  md: "1,75rem",
                  xl: "2.25rem",
                }}
                variant="primary"
                lineHeight="1em"
                as="h2"
              >
                Знайди свою книжку
              </Text>
              <InputGroup
                zIndex={1000}
                width="100%"
                position="relative"
                borderRadius={{
                  base: "0.5rem",
                  sm: "0.5rem",
                  xl: "0.75rem",
                }}
              >
                <Input
                  onKeyDown={handleKeyDown}
                  onChange={(e) => setSearch(e.target.value)}
                  outline="none !important"
                  height={{
                    base: "3.5rem",
                    sm: "2.5rem",
                    md: "3.5rem",
                    xl: "4.5rem",
                  }}
                  fontSize={{
                    base: "1rem",
                    sm: "0.75rem",
                    md: "0.875rem",
                    xl: "1rem",
                  }}
                  px={{ base: "1rem", lg: "2rem" }}
                  _focus={{
                    border: "0.0625rem solid black",
                    outline: "none !important",
                    boxShadow: "none",
                  }}
                  border="0.0625rem solid transparent"
                  _hover={{ border: "0.0625rem solid grey" }}
                  transition="all 0.1s linear"
                  boxShadow={{
                    base: "none",
                    sm: "0.25rem 0.25rem 0.5rem 0.124rem rgba(0, 0, 0, 0.1)",
                    xl: "none !important",
                  }}
                  borderRadius="inherit !important"
                  py={{ base: "1.25rem !important", sm: "auto" }}
                  background="white"
                  placeholder="Що шукаєте?"
                />
                <InputRightElement
                  right={{ base: "0.5rem", sm: "0.25rem", md: "0.5rem" }}
                  top="50%"
                  transform="translateY(-50%)"
                  h={{
                    base: "2.5rem",
                    sm: "2rem",
                    md: "2.5rem",
                    xl: "3.5rem",
                  }}
                  w="5.5%"
                  maxW="7.42% !important"
                  minWidth={{
                    base: "3.25rem",
                    sm: "2.5rem",
                    md: "3rem",
                    xl: "3.75rem",
                  }}
                >
                  <Button
                    onClick={searchBooks}
                    _hover={{ opacity: "0.8" }}
                    _active={{ filter: "brightness(150%)" }}
                    background="black"
                    p="0rem"
                    minWidth="100%"
                    maxWidth="100% !important"
                    maxH={{
                      base: "2.5rem",
                      sm: "2.5rem",
                      md: "3rem",
                      xl: "3.5rem",
                    }}
                    height="100%"
                    borderRadius={{
                      base: "0.5rem",
                      md: "0.5rem",
                      xl: "0.75rem",
                    }}
                  >
                    <Center>
                      <Flex
                        width={{
                          base: "1.25rem",
                          sm: "0.75rem",
                          md: "1rem",
                          "2xl": "1.25rem",
                        }}
                        alignItems="center"
                        justifyContent="center"
                      >
                        <SearchIcon fill="white" />
                      </Flex>
                    </Center>
                  </Button>
                </InputRightElement>
              </InputGroup>
            </Stack>
            <IconButton
              background="transparent"
              onClick={() => onClose(false)}
              _active={{}}
              _hover={{}}
              bottom={{ base: "1rem", xl: "2.5rem" }}
              position="absolute"
              icon={<IoMdClose color="black" size="2.25rem" />}
              aria-label="Close"
            />
          </Flex>
          <Show above="sm">
            <Image
              alt="Vector"
              bottom="1rem"
              right={{ base: "1rem", md: "2rem", lg: "5rem", "2xl": "8.5rem" }}
              height="60%"
              position="absolute"
              src={vector}
            />
          </Show>
        </DrawerContent>
      </DrawerBody>
    </Drawer>
  );
};

export default SearchPanel;
